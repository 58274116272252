import React from 'react';
import GC from '../images/gc-logo.png';
import Facebook from '../images/icon-facebook-32.png';
import Instagram from '../images/icon-instagram-32.png';
import Twitter from '../images/icon-twitter-32.png';

function Craft() {
  return (
    <div id="craftPage">
       <h2>Craft</h2>    
      <div id="content">
            <div className="col-left">
                Quite some time ago I set up a hobby site to sell items that I was making. This has had various incarnations over the years but it's undergoing a new revolution!
                <br/><br/>
                I really enjoy making things, but there's little point in making things unless you have a home for them. This is a project to give me an outlet to sell the things that I enjoy making.
                <br/><br/>
                Introducing:
                <br/><br/>
                <span className="highlight">Gifted Cards</span>
                <br/><br/>
                <img src={GC} alt="Gifted Cards" className="gcLogo" />
                <br/><br/>
                I have moved away from hosting my own store on the website, so that has a home page and links to blog pages. I am in the process of setting up a store with Numonday to sell the items I have already made.
                <br/><br/>
                Check it out! Links are on the right.
                <br/><br/>
                My social media sites are up and running. I won't be making frequent posts but please follow for updates!
                <br/><br/> 
            </div>
            <div className="col-right">
              <a href="https://www.gifted-cards.co.uk" target="_blank" rel="noopener noreferrer" title="Go to the Gifted Cards website in new tab"><img src={GC} className="socialIcon" alt="Gifted cards logo" /> Gifted Cards Website</a>
              <br/><br/>
              <a href="https://www.numonday.com/shop/giftedcards/" target="_blank" rel="noopener noreferrer" title="Go to Numonday in a new tab"><img src={GC} alt="Gifted Cards logo" className="socialIcon" /> Numonday store</a>
              <br/><br/>
              <a href="https://www.twitter.com/giftedcards" target="_blank" rel="noopener noreferrer" title="Go to Twitter in a new tab"><img src={Twitter} alt="Twitter logo" className="socialIcon" /> Twitter @giftedcards</a>
              <br/><br/>
              <a href="https://www.facebook.com/giftedcards" target="_blank" rel="noopener noreferrer" title="Go to Facebook in a new tab"><img src={Facebook} alt="Facebook logo" className="socialIcon" /> Facebook /giftedcards</a>
              <br/><br/>
              <a href="https://www.instagram.com/giftedcardsuk" target="_blank" rel="noopener noreferrer" title="Go to Instagram in a new tab"><img src={Instagram} alt="Instagram logo" className="socialIcon" /> Instagram /giftedcardsuk</a>
              <br/><br/>
            </div>
      </div>
    </div>
    
  )
}

export default Craft;
