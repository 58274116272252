import React from 'react';
import {Link} from 'react-router-dom';
import Professional from '../images/professional.jpg';
import Community from '../images/community.jpg';
import Art from '../images/art.jpg';
import Photo from '../images/photo.jpg';
import Craft from '../images/craft.jpg';
import Fun from '../images/fun.jpg';

class Home extends React.Component {
    constructor(){
        super();
        this.state={
            name: "Community",
            professionalContent: false,
            professionalImage: true,
            communityContent: false,
            communityImage: true,
            artContent: false,
            artImage: true,
            photoContent: false,
            photoImage: true,
            craftContent: false,
            craftImage: true,
            funContent: false,
            funImage: true,
        };
        this.hideComponent = this.hideComponent.bind(this);
    }

    hideComponent(varname){
        switch (varname)
        {
            case "professionalCard":
                this.setState({professionalContent: !this.state.professionalContent});
                this.setState({professionalImage: !this.state.professionalImage});
                break;
            case "communityCard":
                this.setState({communityContent: !this.state.communityContent});
                this.setState({communityImage: !this.state.communityImage});
                break;
            case "artCard":
                this.setState({artContent: !this.state.artContent});
                this.setState({artImage: !this.state.artImage});
                break;
            case "photoCard":
                this.setState({photoContent: !this.state.photoContent});
                this.setState({photoImage: !this.state.photoImage});
                break;
            case "craftCard":
                this.setState({craftContent: !this.state.craftContent});
                this.setState({craftImage: !this.state.craftImage});
                break;
            case "funCard":
                this.setState({funContent: !this.state.funContent});
                this.setState({funImage: !this.state.funImage});
                break;
            default: return;
        }
    }

    render(){
        const{professionalContent, professionalImage,communityContent,communityImage,artContent,artImage, photoContent,photoImage, craftContent,craftImage, funContent, funImage} = this.state;

        return (
            <div id="homeContent">
                <div id="homePageIntro">Thank you for visiting my site. I'm always getting involved in new things so please click on the cards below to find out more about me. </div>
            <div id="cards">
                <div id="professionalCard" className="card" onMouseEnter={() => this.hideComponent("professionalCard")} onMouseLeave={() => this.hideComponent("professionalCard")}>
                    <Link to="/professional" >
                        <div id="professionalTitle" className="cardTitle">Professional</div>
                        <div id="professionalContent" className="cardContent">My professional life in IT</div>
                        <div id="professionalImage"><img src={Professional} alt="Laptop showing code on the screen with a mug in the background" /></div>
                    </Link>
                </div>
                <div id="communityCard" className="card"  onMouseEnter={() => this.hideComponent("communityCard")} onMouseLeave={() => this.hideComponent("communityCard")}>
                    <Link to="/community">
                        <div id="communityTitle" className="cardTitle">Community and Environment</div>
                        <div id="communityContent" className="cardContent">Beach cleaning and the war on plastic</div>
                        <div id="communityImage"><img src={Community} alt="Hands and feet of several people in a circle on grass" /></div>
                    </Link>
                </div>
                <div id="artCard" className="card"  onMouseEnter={() => this.hideComponent("artCard")} onMouseLeave={() => this.hideComponent("artCard")}>
                    <Link to="/art">
                        <div id="artTitle" className="cardTitle">Art</div>
                        <div id="artContent" className="cardContent">Adventures in pencil</div>
                        <div id="artImage"><img src={Art} alt="Colour and graphite pencils in a semi-circle" /></div>
                    </Link>
                </div>
                <div id="photoCard" className="card"  onMouseEnter={() => this.hideComponent("photoCard")} onMouseLeave={() => this.hideComponent("photoCard")}>
                    <Link to="/photography">
                        <div id="photoTitle" className="cardTitle">Photography</div>
                        <div id="photoContent" className="cardContent">My amateur take</div>
                        <div id="photoImage"><img src={Photo} alt="A smartphone being used to take a photograph" /></div>
                    </Link>
                </div>
                <div id="craftCard" className="card"  onMouseEnter={() => this.hideComponent("craftCard")} onMouseLeave={() => this.hideComponent("craftCard")}>
                    <Link to="/craft">
                        <div id="craftTitle" className="cardTitle">Craft</div>
                        <div id="craftContent" className="cardContent">Other arts and crafts</div>
                        <div id="craftImage"><img src={Craft} alt="Scissors cutting shapes in paper" /></div>
                    </Link>
                </div>
                <div id="funCard" className="card"  onMouseEnter={() => this.hideComponent("funCard")} onMouseLeave={() => this.hideComponent("funCard")}>
                    <Link to="/fun">
                        <div id="funTitle" className="cardTitle">Just for Fun!</div>
                        <div id="funContent" className="cardContent">Hobbies and spare time</div>
                        <div id="funImage"><img src={Fun} alt="Hands in the air while people dance at a party"/></div>
                    </Link>
                </div>
            </div>
        </div>    
    )
        }
}

export default Home;
