import React from 'react';
import pantheratigris from '../images/pantheratigris.jpg';
import officialartist from '../images/officialartist.jpg';
import SFS2023 from '../images/SketchForSurvival2023.png';
import sfslogo from '../images/sfslogo.png';
import character1 from '../images/character1.jpg';
import character2 from '../images/character2.jpg';
import character3 from '../images/character3.jpg';
import character4 from '../images/character4.jpg';
import character5 from '../images/character5.jpg';
import character6 from '../images/character6.jpg';
import character7 from '../images/character7.jpg';
import character8 from '../images/character8.jpg';
import colour1 from '../images/colour1.jpg';
import colour2 from '../images/colour2.jpg';
//import colour3 from '../images/colour3.jpg';
import colour4 from '../images/colour4.jpg';
import colour5 from '../images/colour5.jpg';
import colour6 from '../images/colour6.jpg';
import mandala from '../images/mandala.jpg';
import portrait1 from '../images/portrait1.jpg';
import portrait2 from '../images/portrait2.jpg';
import portrait3 from '../images/portrait3.jpg';
import portrait4 from '../images/portrait4.jpg';
import Nelson1 from '../images/Nelson1.jpg';
import Giraffe from '../images/Giraffe.jpg';
import Cookie from '../images/CookieLyon.jpg';
import Johnny from '../images/JohnnyDepp.jpg';

function Art() {
  return (
    <div id="artPage">
       <h2>Art</h2>    
      <div id="content">
          <div className="col-left">
              During the 2020 lockdown I started teaching myself to draw. I have always had an interest in arts and crafts but drawing is not something I had done a lot of in the past.
              <br /><br />
              At the beginning of 2021, I finally submitted a completed sketchbook to the Brooklyn Art Library which was catalogued, digitised and available to check out online. That was the  beginning of my journey.
              <br /><br />
              These pieces of work aren't added here to show artistic perfection, they are far from perfect, but over time they will hopefully show my progress as I continue to try and find my inner artist. We all have to start somewhere.
              <br /><br />
              Please check back soon to see my continuing journey to art.
              <br /><br />

              <div className="gallery">
                <div className="galleryHeading"><h3>Submitted to competitions</h3></div>
                <div className="galleryDescription">Artworks that have been submitted to competitions or collections</div>
                <div className="galleryItem">
                    <div className="galleryTitle">Submission for <a href="https://explorersagainstextinction.co.uk/initiatives/sketch-for-survival/" target="_blank" rel="noreferrer noopener" aria-label="Sketch for Survival (New Window)">Sketch for Survival 2023</a></div>
                    <div className="galleryText">I made the long list but just missed out on the final. My Giraffe received a Highly Commended award and went through for sale on the store. It has now been purchased!!</div>
                    <div className="galleryImage"><a href={Giraffe} title="Open full image (New Window)" target="_blank" rel="noreferrer"><img src={Giraffe} alt="Giraffe"/></a></div>
                </div>
                <div className="galleryItem">
                    <div className="galleryTitle">Submission for <a href="https://explorersagainstextinction.co.uk/initiatives/sketch-for-survival/" target="_blank" rel="noreferrer noopener" aria-label="Sketch for Survival (New Window)">Sketch for Survival 2021</a></div>
                    <div className="galleryText">Success! This piece made it to the Conservation Collection for display in the online gallery and was available to purchase. Unfortunately, I don't know whether it found a home.</div>
                    <div className="galleryImage"><a href={pantheratigris} title="Open full image (New Window)" target="_blank" rel="noreferrer"><img src={pantheratigris} alt="Panthera Tigris"/></a></div>
                </div>

                <div className="galleryHeading"><h3>Portraits and Character Studies</h3></div>
                <div className="galleryDescription">A few attempts a portrait art, newest first, you can see the journey!</div>
                <div className="galleryItem">
                    <div className="galleryTitle">Wilhelm and Simon, as portrayed by Edvin Ryding and Omar Rudberg in the series Young Royals.</div>
                    <div className="galleryImage"><a href={character8} title="Open full image (New Window)" target="_blank" rel="noreferrer"><img src={character8} alt="Wilhelm and Simon, Young Royals"/></a></div>
                </div>
                <div className="galleryItem">
                    <div className="galleryTitle">Alex and Henry, as portrayed by Taylor Zakhar Perez and Nicholas Galitzine in the movie Red, White and Royal Blue.</div>
                    <div className="galleryImage"><a href={character7} title="Open full image (New Window)" target="_blank" rel="noreferrer"><img src={character7} alt="Alex and Henry, Red White and Royal Blue"/></a></div>
                </div>
                <div className="galleryItem">
                    <div className="galleryTitle">Johnny Depp</div>
                    <div className="galleryImage"><a href={Johnny} title="Open full image (New Window)" target="_blank" rel="noreferrer"><img src={Johnny} alt="Portrait of Johnny Depp"/></a></div>
                </div>
                <div className="galleryItem">
                    <div className="galleryTitle">Cookie Lyon as portrayed by Taraji P. Henson</div>
                    <div className="galleryImage"><a href={Cookie} title="Open full image (New Window)" target="_blank" rel="noreferrer"><img src={Cookie} alt="Cookie Lyon in a scene from Empire"/></a></div>
                </div>
                <div className="galleryItem">
                    <div className="galleryTitle">Nelson Mandela</div>
                    <div className="galleryImage"><a href={Nelson1} title="Open full image (New Window)" target="_blank" rel="noreferrer"><img src={Nelson1} alt="Portrait of Nelson Mandela"/></a></div>
                </div>
                <div className="galleryItem">
                    <div className="galleryTitle">Jamal Lyon as portrayed by Jussie Smollett</div>
                    <div className="galleryImage"><a href={portrait4} title="Open full image (New Window)" target="_blank" rel="noreferrer"><img src={portrait4} alt="Portrait of Jamal Lyon"/></a></div>
                </div>
                <div className="galleryItem">
                    <div className="galleryTitle">Terrence Dashon Howard</div>
                    <div className="galleryImage"><a href={portrait3} title="Open full image (New Window)" target="_blank" rel="noreferrer"><img src={portrait3} alt="Portrait of Terrence Dashon Howard"/></a></div>
                </div>
                <div className="galleryItem">
                    <div className="galleryTitle">Lin-Manuel Miranda</div>
                    <div className="galleryImage"><a href={portrait2} title="Open full image (New Window)" target="_blank" rel="noreferrer"><img src={portrait2} alt="Portrait of Lin-Manuel Miranda"/></a></div>
                </div>
                <div className="galleryItem">
                    <div className="galleryTitle">Family Children</div>
                    <div className="galleryImage"><a href={portrait1} title="Open full image (New Window)" target="_blank" rel="noreferrer"><img src={portrait1} alt="Portrait of siblings"/></a></div>
                </div>

                
                <div className="galleryItem">
                    <div className="galleryTitle">Thomas Shelby, portrayed by Cillian Murphy, Peaky Blinders</div>
                    <div className="galleryImage"><a href={character6} title="Open full image (New Window)" target="_blank" rel="noreferrer"><img src={character6} alt="Thomas Shelby" /></a></div>
                </div>
                <div className="galleryItem">
                    <div className="galleryTitle">Alexander Hamilton, portrayed by Lin-Manuel Miranda, Hamilton</div>
                    <div className="galleryImage"><a href={character5} title="Open full image (New Window)" target="_blank" rel="noreferrer"><img src={character5} alt="Alexander Hamilton"/></a></div>
                </div>
                <div className="galleryItem">
                    <div className="galleryTitle">Alexander Hamilton and George Washington, portrayed by Lin-Manuel Miranda and Christopher Jackson, Hamilton</div>
                    <div className="galleryImage"><a href={character4} title="Open full image (New Window)" target="_blank" rel="noreferrer"><img src={character4} alt="Alexander Hamilton and George Washington"/></a></div>
                </div>
                <div className="galleryItem">
                    <div className="galleryTitle">Alexander Hamilton and Eliza Hamilton, portrayed by Lin-Manuel Miranda and Philippa Soo, Hamilton</div>
                    <div className="galleryImage"><a href={character3} title="Open full image (New Window)" target="_blank" rel="noreferrer"><img src={character3} alt="Alexander Hamilton and Eliza Hamilton"/></a></div>
                </div>
                <div className="galleryItem">
                    <div className="galleryTitle">Thomas Shelby,portrayed by Cillian Murphy, Peaky Blinders</div>
                    <div className="galleryImage"><a href={character2} title="Open full image (New Window)" target="_blank" rel="noreferrer"><img src={character2} alt="Thomas Shelby"/></a></div>
                </div>
                <div className="galleryItem">
                    <div className="galleryTitle">Arthur Shelby, portrayed by Paul Anderson, Peaky Blinders</div>
                    <div className="galleryImage"><a href={character1} title="Open full image (New Window)" target="_blank" rel="noreferrer"><img src={character1} alt="Arthur Shelby"/></a></div>
                </div>

                <div className="galleryHeading"><h3>Colour Works</h3></div>
                <div className="galleryDescription">Experiments with colour pencils</div>
                <div className="galleryItem">
                    <div className="galleryTitle">Hazbin Hotel</div>
                    <div className="galleryImage"><a href={colour6} title="Open full image (New Window)" target="_blank" rel="noreferrer"><img src={colour6} alt="Hazbin Hotel"/></a></div>
                </div>
                <div className="galleryItem">
                    <div className="galleryTitle">A scene from Empire</div>
                    <div className="galleryImage"><a href={colour5} title="Open full image (New Window)" target="_blank" rel="noreferrer"><img src={colour5} alt="Silhouette"/></a></div>
                </div>
                <div className="galleryItem">
                    <div className="galleryTitle">Roses</div>
                    <div className="galleryImage"><a href={colour4} title="Open full image (New Window)" target="_blank" rel="noreferrer"><img src={colour4} alt="Roses"/></a></div>
                </div>
                <div className="galleryItem">
                    <div className="galleryTitle">A scene from His Dark Materials</div>
                    <div className="galleryImage"><a href={colour2} title="Open full image (New Window)" target="_blank" rel="noreferrer"><img src={colour2} alt="A scene from His Dark Materials"/></a></div>
                </div>
                <div className="galleryItem">
                    <div className="galleryTitle">A scene from Peaky Blinders</div>
                    <div className="galleryImage"><a href={colour1} title="Open full image (New Window)" target="_blank" rel="noreferrer"><img src={colour1} alt="A scene from Peaky Blinders"/></a></div>
                </div>
                

                <div className="galleryHeading"><h3>Patterns</h3></div>
                <div className="galleryDescription">My first mandala</div>
                <div className="galleryItem">
                    <div className="galleryTitle">Mandala</div>
                    <div className="galleryImage"><a href={mandala} title="Open full image (New Window)" target="_blank" rel="noreferrer"><img src={mandala} alt="Mandala" /></a></div>
                </div>

                <div className="galleryHeading"><h3>Sketchbook</h3></div>
                <div className="galleryDescription">Sadly, on 11th Jan 2023 the Brooklyn Art Library announced the permanent closure of the sketchbook library. I was hoping I would be able to retrieve my little book, it may not have been good but it was a catalyst to many things! Sadly, it seems that it was lost in a catastrophic fire that the library suffered whilst moving the collection to a new location. Fortunately I took a video of the online version before it was taken down, so I have a virtual memory!<br/><br/>
               The theme I chose for the book from those provided was "A Narrative of Me".
               <br/><br/>
                It was viewed 91 times online, so hopefully it was at least been appreciated as the start of a journey! 
                <br/><br/>
                </div>
                
              </div>

        </div>
        <div className="col-right">
            <img src={SFS2023} alt="Sketch for Survival 2023" />
            <img src={sfslogo} alt="Sketch for Survival Conservation Collection" />
            <img src={officialartist} alt="Official Artist of the Brooklyn Art Library" />
        </div>
      </div>
    </div>
    
  )
}

export default Art;
