import React from 'react';
import superheroes from '../images/superheroes.jpg'

class MmagePrivacy extends React.Component {

    render(){
        return (
            <div>
            <h2>mMage Privacy Policy</h2>
            <div id="content">
                <div className="col-left">
                   mMage is a personal vanity project which is in Development and not being released to general circulation.
                   <br/><br/>
                   A privacy policy is required to enable API access which is madness for something set up for learning and development only.
                   <br/><br/>
                   The application stores a user name, email address and password to facilitate login.
                   <br/><br/>
                   The application will store login details for Facebook, Twitter and Instragram.
                   <br /><br/>
                   I do not intend to share my personal details to third parties or to sell my personal information to any third parties through the use of this application.
                   <br /><br/>
                   Anyone stumbling across the app and attempting to use it does so at their own risk but I have no intention of sharing or selling any personal data contained herein.
                   <br/><br/>
                   A proper privacy policy will be provided should I ever finish this project and make the app live for general use.
                </div>
                
                <div className="col-right">
                    <div id="profile-image">
                        <img src={superheroes} alt="Microsoft Virtual Academy Superheroes"/>
                    </div>
                    <div id="profile-details">
                        <br /><br />
                        Amelia Raine
                        <br />
                        Senior Application Developer
                        <br /><br />
                        Social Profiles: 
                        <br />
                        <a href="https://www.linkedin.com/in/ameliaraine" className="profile_link" title="Go to LinkedIn account (New Window)" target="_blank" rel="noreferrer"><span className="icon-linkedin">/ameliaraine</span></a>
                        <br />
                        <a href="https://twitter.com/msrainea" className="profile_link" title="Go to Twitter account (New Window)" target="_blank" rel="noreferrer"><span className="icon-twitter">@msrainea</span></a>
                        <br />
                        <a href="https://www.cybrary.it/members/msrainea/" className="profile_link" title="Go to Cybrary account (New Window)" target="_blank" rel="noreferrer"><span className="icon-right-circle">cybrary.it /msrainea</span></a>
                        <br /> <br />
                        <strong>Image Credits:</strong><br />
                        Site Banner:<br />
                        <a href="https://unsplash.com/@m4r1vs?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText" target="_blank" rel="noreferrer" title="External website for photo credit (New Window)">Marius Niveri</a> on <a href="https://unsplash.com/s/photos/code?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText" target="_blank" rel="noreferrer" title="External website for photo credit (New Window)">Unsplash</a>
                        <br /><br />About Banner:<br />
                        <a href="https://pixabay.com/users/geralt-9301/?utm_source=link-attribution&amp;utm_medium=referral&amp;utm_campaign=image&amp;utm_content=1597565" target="_blank" rel="noreferrer" title="External website for photo credit (New Window)">Gerd Altmann</a> from <a href="https://pixabay.com/?utm_source=link-attribution&amp;utm_medium=referral&amp;utm_campaign=image&amp;utm_content=1597565" target="_blank" rel="noreferrer" title="External website for photo credit (New Window)">Pixabay</a>
                        <br /><br />Profile Banner:<br />
                        <a href="https://pixabay.com/users/geralt-9301/?utm_source=link-attribution&amp;utm_medium=referral&amp;utm_campaign=image&amp;utm_content=2706977" target="_blank" rel="noreferrer" title="External website for photo credit">Gerd Altmann</a> from <a href="https://pixabay.com/?utm_source=link-attribution&amp;utm_medium=referral&amp;utm_campaign=image&amp;utm_content=2706977" target="_blank" rel="noreferrer" title="External website for photo credit (New Window)">Pixabay</a>
                        <br /><br />Social Banner:<br />
                        <a href="https://unsplash.com/@goian?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText" target="_blank" rel="noreferrer" title="Eexternal website for photo credit (New Window)">Ian Schneider</a> on <a href="https://unsplash.com/s/photos/code?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText" target="_blank" rel="noreferrer" title="Link to external website for photo credit (New Window)">Unsplash</a>
                    </div>
                </div>
            </div>
            </div>    
        )
    }
}

export default MmagePrivacy;
