import React from 'react';
import MMLogo from '../images/M&M_Logo.png';
import Facebook from '../images/icon-facebook-32.png';
import Instagram from '../images/icon-instagram-32.png';
import Twitter from '../images/icon-twitter-32.png';

function Photography() {
  return (
    <div id="photoPage">
       <h2>Photography</h2>    
      <div id="content">
            <div className="col-left">
                New for 2022! I have no illusions of being a professional photographer and never claim to be one, but occasionally I manage to point and click at just the right time and capture a nice image so I thought I'd start to share my favourites.
                <br/><br/>
                Introducing:
                <br/><br/>
                <span className="highlight">Moments and Moxie</span>
                <br/><br/>
                <img src={MMLogo} alt="Graphic design logo with a coloured spiral around coloured pencils in a circle around a camera lens" />
                <br/><br/>
                You can find out more about this on Picfair, links on the right.
                <br/><br/>
                The social media pages are private at the moment as I'm not sure yet how frequently I'll have new content to post, but you're welcome to send a follow request.
                <br/><br/>
                The social pages also include artwork, these can be seen on the <a href="/art">Art</a> page on this site.
                <br/><br/>
            </div>
            <div className="col-right">
              <a href="https://momentsandmoxie.picfair.com" target="_blank" rel="noopener noreferrer" title="Go to Picfair in a new tab"><img src={MMLogo} className="socialIcon" alt="Moments and Moxie" /> Picfair Moments and Moxie </a>
              <br/><br/>
              <a href="https://www.instagram.com/momentsandmoxie" target="_blank" rel="noopener noreferrer" title="Go to Instagram in a new tab"><img src={Instagram} alt="Instagram logo" className="socialIcon" /> Instagram @momentsandmoxie</a>
              <br/><br/>
              <a href="https://www.twitter.com/momentsandmoxie" target="_blank" rel="noopener noreferrer" title="Go to Twitter in a new tab"><img src={Twitter} alt="Twitter logo" className="socialIcon" /> Twitter @momentsandmoxie</a>
              <br/><br/>
              <a href="https://www.facebook.com/momentsandmoxie" target="_blank" rel="noopener noreferrer" title="Go to Facebook in a new tab"><img src={Facebook} alt="Facebook logo" className="socialIcon" /> Facebook /momentsandmoxie</a>
              <br/><br/>
            </div>
      </div>
    </div>
  )
}

export default Photography;
