import React from 'react';
import bannersocial from '../images/bannersocial.jpg';
import superheroes from '../images/superheroes.jpg';

class Community extends React.Component {
    constructor(){
        super();
        this.state={
            name: "Community",
            divseashorty: false,
            divhighcliffebct: false,
            divfriarscliffbct: false,
            divhighcliffepf: false,
            divmudefordqcc: false,
            divcmarcuk: false,
            divgiftedcards: false,
            divescape: false
        };
        this.hideComponent = this.hideComponent.bind(this);
    }

    hideComponent(varname){
        switch (varname)
        {
            case "divseashorty":
                this.setState({divseashorty: !this.state.divseashorty});
                break;
            case "divhighcliffebct":
                this.setState({divhighcliffebct: !this.state.divhighcliffebct});
                break;
            case "divfriarscliffbct":
                this.setState({divfriarscliffbct: !this.state.divfriarscliffbct});
                break;
            case "divhighcliffepf":
                this.setState({divhighcliffepf: !this.state.divhighcliffepf});
                break;
            case "divmudefordqcc":
                this.setState({divmudefordqcc: !this.state.divmudefordqcc});
                break;
            case "divcmarcuk":
                this.setState({divcmarcuk: !this.state.divcmarcuk});
                break;
            default: return;
        }
    }


    render(){
        const {divseashorty,divhighcliffebct,divfriarscliffbct,divhighcliffepf,divmudefordqcc,divcmarcuk} = this.state;

        return (
            <div id="communityPage">

            <h2>Community and Environment</h2>    
            <div id="content">
                <div className="col-left">
                    <div id="banner-image">
                        <img src={bannersocial} alt="Passion led us here"/>
                    </div>
                    <div id="social-content">
                        <br /><br />
                        Work life balance is really important and I like to fill my time with all kinds of pursuits.  
                        <br /><br />
                        I have lots of interests but I run three small hobby businesses and six social ventures and am doing my best to help our local environment by keeping our beautiful area clean and free of plastic and other pollution.
                        <br /><br />
                        Please click the headings below to find out a little more about each venture - all social media links are included so you can find me online.
                        <br /><br />
                        <div id="seashorty" className="socialToggle" onClick={() => this.hideComponent("divseashorty")}>
                            <h3>Seashorty</h3>
                        </div>
                        {divseashorty && 
                        <div id="divseashorty" className="socialDiv">
                            Seashorty is a handle I use online to share my solo adventures in beach cleaning and living plastic free.  I do a lot of solo beach cleans and collect important stats about the litter that I find. I also organise regular community beach clean events to get other people involved.
                            <br/><br/>
                            As at 21st April 2024, I have collected 78,389 pieces of litter from local beaches. It's all catalogued on my website.
                            <br /><br />
                            <a href="https://www.facebook.com/seashorety" target="_blank" rel="noreferrer" title="Seashorty Facebook page (New Window)"><div className="icon-facebook">/seashorety</div></a>
                            <a href="https://www.twitter.com/seashorty" target="_blank" rel="noreferrer" title="Seashorty Twitter page (New Window)"><div className="icon-twitter">@seashorty</div></a>
                            <a href="https://www.instagram.com/seashorty" target="_blank" rel="noreferrer" title="Seashorty Instagram page (New Window)"><div className="icon-instagram">@seashorty</div></a>
                            <br /><br />
                        </div>
                        }
                        <div id="highcliffebct" className="socialToggle" onClick={() => this.hideComponent("divhighcliffebct")}>
                            <h3>Highcliffe Beach Clean Team</h3>
                        </div>
                        {divhighcliffebct && 
                        <div id="divhighcliffebct" className="socialDiv">
                            Highcliffe Beach Clean Team is a group that I lead, supported by BCP Council and Litter Free Dorset, to organise quarterly cleanups on Highcliffe Beach.
                            <br /><br />
                            <a href="https://www.facebook.com/highcliffebct" target="_blank" rel="noreferrer" title="Highcliffe Beach Clean Team facebook page (New Window)"><div className="icon-facebook">/highcliffebct</div></a>
                            <a href="https://www.twitter.com/highcliffebct" target="_blank" rel="noreferrer" title="Highcliffe Beach Clean Team Twitter pag (New Window)"><div className="icon-twitter">@highcliffebct</div></a>
                            <a href="https://www.instagram.com/highcliffebct" target="_blank" rel="noreferrer" title="Highcliffe Beach Clean Team Instagram page (New Window)"><div className="icon-instagram">@highcliffebct</div></a>
                            <br /><br />
                        </div>
                        }
                        <div id="friarscliffbct" className="socialToggle" onClick={() => this.hideComponent("divfriarscliffbct")}>
                            <h3>Friars Cliff Beach Care</h3>
                        </div>
                        {divfriarscliffbct && 
                        <div id="divfriarscliffbct" className="socialDiv">
                            Friars Cliff Beach Care is a group that I lead, supported by BCP Council and Litter Free Dorset, to organise quarterly cleanups on Friars Cliff Beach.
                            <br /><br />
                            <a href="https://www.facebook.com/friarscliffbc" target="_blank" rel="noreferrer" title="Friars Cliff Beach Care facebook page (New Window)"><div className="icon-facebook">/friarscliffbc</div></a>
                            <a href="https://www.twitter.com/riarscliffbc" target="_blank" rel="noreferrer" title="Friars Cliff Beach Care Twitter pag (New Window)"><div className="icon-twitter">@hfriarscliffbc</div></a>
                            <a href="https://www.instagram.com/riarscliffbc" target="_blank" rel="noreferrer" title="Friars Cliff Beach Care Instagram page (New Window)"><div className="icon-instagram">@hfriarscliffbc</div></a>
                            <br /><br />
                        </div>
                        }
                        <div id="highcliffepf" className="socialToggle" onClick={() => this.hideComponent("divhighcliffepf")}>
                            <h3>Plastic Free Highcliffe</h3>
                        </div>
                        {divhighcliffepf && 
                        <div id="divhighcliffepf" className="socialDiv">
                            Plastic Free Highcliffe is a community that I lead as part of the Surfers Against Sewage Plastic Free Communities campaign.  I am trying to help Highcliffe achieve plastic free status.
                            <br /><br />
                            <a href="https://www.plasticfreehighcliffe.org.uk" target="_blank" rel="noreferrer" title="Plastic Free Highcliffe Website (New Window)">Plastic Free Highcliffe Website</a>
                            <br />
                            <a href="https://www.facebook.com/PlasticFreeHighcliffeOnSea/" target="_blank" rel="noreferrer" title="Plastic Free Highcliffe facebook page (New Window)"><div className="icon-facebook">/plasticfreehighcliffeonsea</div></a>
                            <a href="https://www.twitter.com/pfhighcliffe" target="_blank" rel="noreferrer" title="Plastic Free Highcliffe Twitter page (New Window)"><div className="icon-twitter">@pfhighcliffe</div></a>
                            <a href="https://www.instagram.com/pfhighcliffe" target="_blank" rel="noreferrer" title="Plastic Free Highcliffe Instagram page (New Window)"><div className="icon-instagram">@pfhighcliffe</div></a>
                            <br /><br />
                        </div>
                        }
                        <div id="mudefordqcc" className="socialToggle" onClick={() => this.hideComponent("divmudefordqcc")}>
                            <h3>Mudeford Quay Cleanup Crew</h3>
                        </div>
                        {divmudefordqcc && 
                        <div id="divmudefordqcc" className="socialDiv">
                            Mudeford Quay Cleanup Crew is a group that I lead, supported by BCP Council, to organise regular cleanups at Mudeford Quay, supporting local events and bringing people together to look after the local area.
                            <br /><br />
                            <a href="https://www.mudefordquaycleanup.org.uk" target="_blank" rel="noreferrer" title="Mudeford Quay Cleanup Crew Website (New Window)">Mudeford Quay Cleanup Crew Website</a>
                            <br />
                            <a href="https://www.facebook.com/mudefordquaycc/" target="_blank" rel="noreferrer" title="Mudeford Quay Cleanup Crew facebook page (New Window)"><div className="icon-facebook">/mudefordquaycc</div></a>
                            <a href="https://www.twitter.com/mudefordquaycc" target="_blank" rel="noreferrer" title="Mudeford Quay Cleanup Crew Twitter page (New Window)"><div className="icon-twitter">@mudefordquaycc</div></a>
                            <a href="https://www.instagram.com/mudefordquaycc" target="_blank" rel="noreferrer" title="Mudeford Quay Cleanup Crew Instagram page (New Window)"><div className="icon-instagram">@mudefordquaycc</div></a>
                            <br /><br />
                        </div>
                        }
                        <div id="cmarcuk" className="socialToggle" onClick={() => this.hideComponent("divcmarcuk")}>
                            <h3>Christchurch Marine Conservation</h3>
                        </div>
                        {divcmarcuk && 
                        <div id="divcmarcuk" className="socialDiv">
                            Christchurch Marine Conservation is a group that I lead, to engage with the local community over issues important for our coastline through knowledge, education and action. In 2024 I am leading three citizen science projects to ensure we are surveying our local coastline and recording important data.
                            <br /><br />
                            <a href="https://cmarcuk.org.uk" target="_blank" rel="noreferrer" title="Christchurch Marine Conservation Website (New Window)">Christchurch Marine Conservation Website</a>
                            <br />
                            <a href="https://www.facebook.com/cmarcuk/" target="_blank" rel="noreferrer" title="Christchurch Marine Conservation facebook page (New Window)"><div className="icon-facebook">/cmarcuk</div></a>
                            <a href="https://www.twitter.com/cmarcuk" target="_blank" rel="noreferrer" title="Christchurch Marine Conservation Twitter page (New Window)"><div className="icon-twitter">@cmarcuk</div></a>
                            <a href="https://www.instagram.com/cmarcuk" target="_blank" rel="noreferrer" title="Christchurch Marine Conservation Instagram page (New Window)"><div className="icon-instagram">@cmarcuk</div></a>
                            <br /><br />
                        </div>
                            }
                    </div>
                </div>
                
                <div className="col-right">
                    <div id="profile-image">
                        <img src={superheroes} alt="Microsoft Virtual Academy Superheroes"/>
                    </div>
                    <div id="profile-details">
                        <br /><br />
                        Amelia Raine
                        <br />
                        Senior Application Developer
                        <br /><br />
                        Social Profiles: 
                        <br />
                        <a href="https://www.linkedin.com/in/ameliaraine" className="profile_link" title="LinkedIn account (New Window)" target="_blank"  rel="noreferrer"><span className="icon-linkedin">/ameliaraine</span></a>
                        <br />
                        <a href="https://twitter.com/msrainea" className="profile_link" title="Twitter account (New Window)" target="_blank"  rel="noreferrer"><span className="icon-twitter">@msrainea</span></a>
                        <br />                        
                    </div>
                </div>
            </div>
            </div>
            
        )
    }
}

export default Community;
