import React from 'react';
import bannerprofile from '../images/bannerprofile.jpg';
import superheroes from '../images/superheroes.jpg';

class Training extends React.Component {
    constructor(){
        super();
        this.state={
            name: "Training",
            trainingProContent: false,
            trainingOtherContent: false
        };
        this.hideComponent = this.hideComponent.bind(this);
    }

    hideComponent(varname){
        switch (varname)
        {
            case "trainingProContent":
                this.setState({trainingProContent: !this.state.trainingProContent});
                break;
            case "trainingOtherContent":
                this.setState({trainingOtherContent: !this.state.trainingOtherContent});
                break;
            default: return;
        }
    }

    render(){
        const {trainingProContent,trainingOtherContent} = this.state;

        return (
            <div id="trainingPage">
            <h2>Profile</h2>    
            <div id="content">
                <div className="col-left">
                    <div id="banner-image">
                        <img src={bannerprofile} alt="Never Stop Learning"/>
                    </div>
                    <div id="profile-content">
                        <h2>Training In Progress / Enrolled</h2>
                        <ul>
                            <li>EdX: Tech for Good: The Role of ICT in Achieving the SDGs</li>
                            <li>2023: Social Media Marketing Diploma (Centre of Excellence)</li>
                            <li>Search Engine Optimisation (SEO) Diploma</li>
                            <li>Master Bulma CSS Framework</li>
                        </ul>
                        <br /><br />
                        <h2>Staying up to date</h2>
                        <p>It's  a fast-moving world but I try to stay on top of things as far as possible using these resources, among others</p>
                        <ul>
                            <li>DailyDev</li>
                            <li>TLDR Newsletter</li>
                            <li>Superhuman AI Newsletter</li>
                            <li>Instagram and Twitter accounts</li>
                        </ul>
                        <br /><br />

                        <div id="trainingPro">
                            <div id="trainingProTitle" onClick={() => this.hideComponent("trainingProContent")}>
                                <h2>Completed Training - IT/Professional<span className="icon-down-open"></span></h2>
                            </div>
                            {trainingProContent &&
                                <div id="trainingProContent">
                                        
                                    I have found that LinkedIn only allows you to record details of up to 50 training courses.  
                                    <br />
                                    For someone like me who has been focusing on continuous learning, that's simply not enough space.
                                    <br />
                                    The list here is in reverse order, my latest completed courses are at the top.   
                                    <br /><br />
                                    This still doesn't give a complete picture.  A lot of things I have learned on the fly whilst developing and that's not reflected here.  For example, Android development and Java.
                                    <br /><br />                        
                                    <ul>
                                        <li>2023    Ultimate ASP.NET Core Web API Development</li>
                                        <li>2023    ChatGPT Content Writing Masterclass | Prompt Engineering</li>
                                        <li>2023    Introduction to Stable Diffusion for Developers and Designers</li>
                                        <li>2023    DALL-E Mastery: Create Impressive AI Art</li>
                                        <li>2023    Learn Midjourney for your future 6 figure career</li>
                                        <li>2023    Adobe Firefly: A Guide to AI Art, Generative AI, Photoshop</li>
                                        <li>2023    AI 101 fundamentals for managers & leaders</li>
                                        <li>2023    Master Laravel 9, Vue 3 and Inertia full stack 2023</li>
                                        <li>2022    Cybrary: Secure Coding </li>
                                        <li>2022    Code with Mosh: Redux Tutorial</li>
                                        <li>2022    Code with Mosh: React JS - React Tutorial</li>
                                        <li>2021    LinuxFoundationX LFD106x Secure Software Development: Verification and More Specialized Topics</li>
                                        <li>2021    LinuxFoundationX LFD105x Secure Software Development: Implementation</li>
                                        <li>2021    LinuxFoundationX: LFD104x Secure Software Development: Requirements, Design, and Reuse</li>
                                        <li>2021    Udemy:  Next Level CSS Creative Hover & Animation Effects</li>
                                        <li>2021    Microsoft:  DevOps and GitHub </li>
                                        <li>2021    Contensis:  Introducing Blocks</li>
                                        <li>2021    Microsoft:  Data Fundamentals</li>
                                        <li>2021    Microsoft:  Azure AI FUndamentals</li>
                                        <li>2021    Microsoft:  Modernize .NET Apps</li>
                                        <li>2021    AmigosCode: Git and GitHub Tutorial 2021</li>  
                                        <li>2021    edX IBM:    Developing Cloud Applications with Node.js and React</li>  
                                        <li>2020    OpenLearn:  Themes and theories for working in Virtual Project Teams</li>
                                        <li>2020    OpenLearn:  Software Development for Enterprise Systems</li>
                                        <li>2020    OpenLearn:  Digital Forensics</li>
                                        <li>2020    OpenLearn:  Designing the user interface</li>
                                        <li>2020    OpenLearn:  Approaches to Software Development</li>
                                        <li>2020    OpenLearn:  An Introduction to Interaction Design</li>
                                        <li>2020    OpenLearn:  Mastering Systems Thinking in Practice </li>
                                        <li>2019    Udemy: Ethical Hacking </li>
                                        <li>2019    Udemy: Computer Hacking Forensic Investigator</li>
                                        <li>2018    Ethical Hacking from Scratch</li>
                                        <li>2017    Edx HarvardX: CS50x - Introduction to Computer Science </li> 
                                        <li>2017    ContensisDevelopment, Zengenti</li>
                                        <li>2017    M20483, Programming in C#</li>
                                        <li>2017    M20778, Analyzing Data with PowerBI </li>
                                        <li>2017    M10961, Automating Administration with Windows PowerShell v4.0</li>
                                        <li>2017    M20486, Developing ASP.NET MVC 4 Web Applications </li>
                                        <li>2016    APIs with Rails</li>
                                        <li>2016    Flexbox</li>
                                        <li>2016    CompTIA Security+</li>
                                        <li>2016    Social Engineering and Manipulation</li>
                                        <li>2016    Computer Hacking and Forensics</li>
                                        <li>2016    CompTIA Network+</li>
                                        <li>2016    CompTIA Cloud+</li>
                                        <li>2016    Cryptography</li>
                                        <li>2016    ITIL Foundation</li>
                                        <li>2016    Secure Coding</li>
                                        <li>2016    Penetration testing and Ethical Hacking</li>
                                        <li>2016    Project Management Professional (PMP)</li>
                                        <li>2015    Microsoft Azure Fundamentals</li>
                                        <li>2015    Microsoft Azure Fundamentals: Websites</li>
                                        <li>2015    Enable the Consumerization of IT Jump Start</li>
                                        <li>2015    Private Cloud Computing and Infrastructure Management</li>
                                        <li>2015    Designing Applications for Windows Azure Jump Start</li>
                                        <li>2015    Developing Microsoft Azure Solutions</li>
                                        <li>2015    Introduction to jQuery</li>
                                        <li>2015    Introduction to ASP.NET MVC</li>
                                        <li>2015    Building Responsive UI with Bootstrap</li>
                                        <li>2014    Windows Azure for IT Pros Jump Start</li>
                                        <li>2014    Getting Started with PowerShell 3.0 Jump Start</li>
                                        <li>2014    Managing Consumer Devices on a Corporate Network</li>
                                        <li>2014    Build a Private Cloud with Windows Server & System Center Jump Start</li>
                                        <li>2014    Introduction to Private, Hybrid and Public Cloud</li>
                                        <li>2014    Private Cloud: Infrastructure Components</li>
                                        <li>2014    Introduction to Microsoft Virtualisation</li>
                                        <li>2014    Windows Server 2012 R2 Virtualization</li>
                                        <li>2014    Microsoft Virtualization: VMware Professionals</li>
                                        <li>2014    What's New in Windows Server 2012 R2 Jump Start</li>
                                        <li>2014    Database Fundamentals</li>
                                        <li>2014    Office Guides: SharePoint Online Overview for IT Pros</li>
                                        <li>2014    Office Guides: Introduction to SharePoint</li>
                                        <li>2014    C# Fundamentals for Absolute Beginners</li>
                                        <li>2014    Try jQuery</li>
                                        <li>2014    JavaScript Road Trip Part 3</li>
                                        <li>2014    JavaScript Road Trip Part 2</li>
                                        <li>2014    JavaScript Road Trip Part 1</li>
                                        <li>2014    Rails for Zombies Redux</li>
                                        <li>2014    Try Ruby</li>
                                        <li>2014    Try Git</li>
                                        <li>2014    ITIL V3 Foundations</li>
                                        <li>2011    Introduction to Microsoft Dynamics CRM 2013</li>
                                        <li>2010    NVQIII IT Professional</li>
                                        <li>2009    Certified Internet Webmaster: Website Design Manager</li>
                                        <li>2009    Microsoft Office Specialist Master Expert (Office 2003)</li>
                                        <li>2004    Basic Imports and IPR</li>
                                        <li>2003    Computer Literacy (Distinction)</li>
                                        <li>2003    CIM Foundation Certificate in Marketing (Distinction)</li>
                                        <li>2003    Level 3 National Award in Telematics (Wizard on the Web)</li>
                                        <li>2001    Computer Intermediate Course (Distinction)</li>
                                        <li>2000    Computer Foundation Course (Distinction)</li>
                                        <li>1997    NVQIII Business Administration</li>
                                        <li>1996    Starter Course in Exports</li>
                                        <li>1996    Introduction to Pagemaker </li>
                                        <li>1996    Word Advanced</li>
                                        <li>1995    NVQII Business Administration</li>
                                        <li>1995    Quattro Pro 5</li>
                                        <li>1995    WordPerfect</li>
                                        <li>1995    Windows</li>
                                    </ul> 
                                    
                                    <br /><br />
                                    <h2>Webinars - What I'm Watching</h2>
                                    
                                    <ul>
                                        <li>TextHelp - Dymystifying Digital Acessibility with CDDO</li>
                                        <li>SiteImprove - Does your website take too long to load?</li>
                                        <li>SiteImprove - What is page performance and why it matters</li>
                                        <li>New Signature - PowerApps and Teams - Enabling Digital Business Processes</li>
                                        <li>Zengenti - Why content modelling is so hot right now</li>
                                        <li>Zengenti - Cookie Banners in 2020</li>
                                        <li>Microsoft - Modernising Web Applications and Data</li>
                                        <li>Microsoft - Community-Driven Security with Azure Sentinel and GitHub</li>
                                        <li>Microsoft - Develop AI Responsibly</li>
                                        <li>Bytes - The Importance of Dark Web Monitoring with Digital Shadows</li>
                                        <li>Microsoft Tech Summit: Low code app creation using PowerApps</li>
                                        <li>Microsoft Tech Summit: Inform and engage employees with SharePoint communication sites and news</li>
                                        <li>Microsoft Tech Summit: Deep dive: Advanced workflow automation with Microsoft Flow</li>
                                        <li>Microsoft Tech Summit: SharePoint Development in the Enterprise</li>
                                        <li>Microsoft – Microsoft tech Summit 2018: GDPR and Office 365</li>
                                        <li>Microsoft - Countdown: preparing for GDPR, GDPR and Azure</li>
                                        <li>Microsoft - Countdown: preparing for GDPR, Accelerating GDPR Compliance with Compliance manager</li>
                                        <li>Microsoft - Countdown: preparing for GDPR, What to expect under GDPR from your cloud provider and insights from Microsoft on GDPR</li>
                                        <li>Microsoft - Countdown: Preparing for GDPR, GDPR and Compliance Documentation </li>
                                        <li>Gartner - The Top 10 Basic Changes Needed for GDPR Compliance on demand</li>
                                        <li>BrightTALK - Blazing the Trail: Changes facing the CyberSecurity Industry</li>
                                        <li>BrightTALK - Why you should monitor IT Service Performance in Pre-Production Environments</li>
                                        <li>Pluralsight - Get ready for the web in 2018</li>
                                        <li>CollabNet - Continuous Testing, Why move testing left?</li>
                                        <li>BrightTALK - Data Fabric @ Scale: Breaking through legacy data architectures</li>
                                        <li>BrightTALK - The Digital Workforce Wants YOU: Consider a Career in Cybersecurity</li>
                                        <li>BrightTALK - Cybercrime Tactics and Techniques Q2 2017 </li>
                                        <li>OutSystems - Low-Code Development: Why Low-Code, Why Now </li>
                                        <li>BrightTALK - Secure Coding Practices: Avoiding SQL Injection Attacks </li>
                                        <li>BrightTALK - [Live panel] Cyber Attack Trends and Staying One-step-ahead</li>
                                        <li>Recorded Future - Deep Web</li>
                                        <li>Microsoft Modern Workplace - Cyber Intelligence: The Human Element</li>
                                        <li>Microsoft Azure Monthly Webinar Series - Building Mobile Apps on Azure</li>
                                        <li>Microsoft Azure Monthly Webinar Series - Building modern websites using Microsoft Azure</li>
                                        <li>Microsoft Modern Workplace - Visualize: The power of data storytelling</li>
                                        <li>BrightTALK - Social Engineering - Are you the weakest link?</li>
                                        <li>BrightTALK - Bimodal Bake Off: Agility versus Stability in AppDev</li>
                                        <li>Microsoft Modern Workplace - Deliver apps using Microservice architectures on Azure</li>
                                        <li>Microsoft - Microsoft Dynamics 365 First Look</li>
                                        <li>BrightTALK - The Advntages of User ID / Awareness in Public Sector Network Security</li>
                                        <li>BrightTALK -Your Life is the Attack Surface: The Risks of IoT in 2016</li>
                                        <li>BrightTALK - The Magic Bullet: How Social Engineering is the Key</li>
                                        <li>Cisco Talos Hunting the Hunters</li>
                                        <li>Microsoft Modern Workplace Series</li>
                                        <li>BrightTALK - SSL Traffic - the Cyber Criminal's Best Frind</li>
                                        <li>BrightTALK - Cyptocurrencies: A Catalyst Driving Cyber Crime</li>
                                        <li>BrightTALK - The Rise of Threat Actor Groups: What you need to know</li> 
                                    </ul>
                                </div>
                            }
                        </div>
                        <br /><br />
                        <div id="trainingOther">
                            <div id="trainingOtherTitle" onClick={() => this.hideComponent("trainingOtherContent")}>
                                <h2>Completed Training - Other Interest<span className="icon-down-open"></span></h2>   
                            </div>
                            {trainingOtherContent && 
                                <div id="trainingOtherContent">
                                    <p>There is life beyond work, here's what I get up to when I'm not improving work-related skills</p>
                                    <br />
                                    <strong>Environment:</strong>
                                    <p>For those people looking me up for my beach cleaning and conservation activities</p>
                                    <ul>
                                        <li>2023: Udemy - Plastic Waste Pollution: causes, impacts & solutions</li>
                                        <li>2021: New Skills Academy - Emergency First Aid at Work</li>
                                        <li>2021: New Skills Academy - Social Media Marketing</li>
                                        <li>2021: Centre of Excellence - Diploma in Oceanography</li>
                                        <li>2021: Centre of Excellence - Diploma in Meteorology</li>
                                        <li>2021: Centre of Excellence - Diploma in Living Sustainably</li>
                                        <li>2021: UNEP - Nature-based Solutions for Disaster and Climate Resilience</li>
                                        <li>2020: Centre of Excellence - Diploma in Marine Biology</li>
                                        <li>2020: Centre of Excellence - Diploma in Conservation</li>
                                        <li>2020: OpenLearn - The Oceans</li>
                                        <li>2020: OpenLearn - Water in the UK</li>
                                        <li>2020: OpenLearn - Managing Coastal Environments</li>
                                        <li>2020: OpenLearn - Watching the Weather</li>
                                        <li>2020: OpenLearn - Ecology and Ecosystems</li>
                                        <li>2019: UN Environment Massive Open Online Course on Marine Litter - Expert Track</li>
                                        <li>2019: UN Environment Massive Open Online Course on Marine Litter - Leadership Track</li>
                                    </ul>
                                    <br />
                                    <strong>Other Interest</strong>
                                    <ul>
                                        <li>2024: Midjourney, Dall-E, Stable Diffusion: AI Art Masterclass</li>
                                        <li>2024: Herbalism</li>
                                        <li>2023: Ho'Oponopono Practitioner Certification</li>
                                        <li>2023: Write Dreamy and Imaginative Poetry with Creative Writing</li>
                                        <li>2023: Songwriting: Creative Lyric Writing Approaches</li>
                                        <li>2023: Secret Sauce of Great Writing</li>
                                        <li>2023: Digital Photography Diploma</li>
                                        <li>2023: Introduction to Bitcoin, Blockchain and Cryptocurrencies Diploma</li>
                                        <li>2022: ICMP - Introduction to Songwriting</li>
                                        <li>2020: Centre of Excellence - How to be a super learner diploma</li>
                                        <li>2020: OpenLearn - Infection and Immunity</li>
                                        <li>2020: OpenLearn - Understanding Antibiotic Resistance</li>
                                        <li>2020: OpenLearn - The technology of Crime Control</li>
                                        <li>2020: OpenLearn - Visions of Protest: Graffiti</li>
                                        <li>2020: OpenLearn - Multidisciplinary Study, the value and benefits</li>
                                        <li>2020: OpenLearn - Social Construction and Social Constructionism</li>
                                        <li>2020: OpenLearn - The politics of racial violence in Britain</li>
                                        <li>2020: OpenLearn - Learning from audio-visual material - Introducing Surveillance</li>
                                        <li>2020: OpenLearn - Reading Visual Images</li>
                                        <li>2020: OpenLearn - Nuclear Power: friend or foe</li>
                                        <li>2020: OpenLearn - Social Psychology and Politics</li>
                                    </ul>
                                </div>
                            }
                        </div>         
                    </div>
                </div>
                                
                <div className="col-right">
                    <div id="profile-image">
                        <img src={superheroes} alt="Microsoft Virtual Academy Superheroes"/>
                    </div>
                    <div id="profile-details">
                        <br /><br />
                        Amelia Raine
                        <br />
                        Senior Application Developer
                        <br /><br />
                        Social Profiles: 
                        <br />
                        <a href="https://www.linkedin.com/in/ameliaraine" className="profile_link" title="Link to LinkedIn account"><span className="icon-linkedin">/ameliaraine</span></a>
                        <br />
                        <a href="https://twitter.com/msrainea" className="profile_link" title="Link to Twitter account"><span className="icon-twitter">@msrainea</span></a>
                        <br />
                        <br />                        
                    </div>
                </div>
            </div>
            </div>
            
        )
    }
}

export default Training;
