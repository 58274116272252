import React from 'react';
import {Link} from 'react-router-dom';

import sitebanner from '../images/sitebanner.jpg';
import superheroes from '../images/superheroes.jpg';
import bannersocial from '../images/bannersocial.jpg';

function Professional() {
  return (
    <div id="professionalPage">
       <h2>Senior Application Developer</h2>
      <div id="content">
          <div className="col-left">
              <div id="banner-image">
                  <img src={sitebanner} alt="Backlit keyboard"/>
              </div>
              <div id="introduction">
                  <br /><br />
                  The last few years have brought about quite a few changes in my professional path.  My focus has now shifted to web-based development and it is a good fit.
                  <br /><br />
                  I am found at my happiest with my head in thousands of lines of code in various languages, often more than one at once!
                  <br /><br />
                  I have many IT interests outside of my primary focus and all that can be found on the other pages of this site.
                  <br /><br />
                  I quit mainstream education at age 17, wanting instead to get out into the real world and start working and I've never stopped, only moving forwards and upwards.  I never stopped learning and I have undertaken a lot of self-led training, along with the odd classroom or virtual course courtesy of employers.
                  <br /><br />
                  I have a solid, wide-ranging background from both the aerospace industry and from local government, through global sales and customer support teams, technical documentation, team administration, first line systems support, second line application support and finally arriving in development.
                  <br /><br />
                  I am more than familiar with documentation and change control and can provide full circle development process from requirements gathering to delivery.
                  <br /><br />
                  Find out more on my <Link to="/about">About</Link> page.
                  <br /><br />
              </div>
              
          </div>
          
          <div className="col-right">
              <div id="profile-image">
                  <img src={superheroes} alt="Microsoft Virtual Academy Superheroes"/>
              </div>
              <div id="profile-details">
                  <br /><br />
                  Amelia Raine
                  <br />
                  Senior Application Developer
                  <br /><br />
                  Social Profiles: 
                  <br />
                  <a href="https://www.linkedin.com/in/ameliaraine" className="profile_link" title="Link to LinkedIn account"><span className="icon-linkedin">/ameliaraine</span></a>
                  <br />
                  <a href="https://twitter.com/msrainea" className="profile_link" title="Link to Twitter account"><span className="icon-twitter">@msrainea</span></a>
                  <br />                     
              </div>
          </div>

          <div className="col-full">
              <img src={bannersocial} alt="Passion Led Us Here" />
              <h2>It's not all about work</h2>
              
              <div className="social-content">
                  In that magical time between work days that we call "free" I like to keep myself busy. So between spending time with the family, updating my training and going to various concerts and events I also run the following businesses and social ventures:
                  <ul>
                      <li>Seashorty</li>
                      <li>Highcliffe Beach Clean Team</li>
                      <li>Friars Cliff Beach Care</li>
                      <li>Plastic Free Highcliffe</li>
                      <li>Mudeford Quay Cleanup Crew</li>
                      <li>Christchurch Marine Conservation</li>
                      <li>Gifted Cards</li>
                      <li>Moments and Moxie</li>
                      <li>Escap-e</li>
                  </ul>
                  For more information on any of these, please visit my <Link to="/Community">Community</Link> page.
                  <br /><br />
              </div>
          </div>
      </div>
    </div>
    
  )
}

export default Professional;
