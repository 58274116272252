import React, { Component } from 'react';
import {Link} from 'react-router-dom';
import siteimage from '../images/SiteImage-Mod9.png';

class Header extends Component {
    
    render() {
      return (
        <header>
            <div className="row site-header">
                <div className="site-logo">
                    <img src={siteimage} alt="Amelia Raine" />
                </div>
                <div className="site-cover">
                    <h1>Amelia Raine</h1>
                    <br />
                    <div id="siteMenu"><span></span></div>
                </div>
                <div className="site-social">
                    <div><a href="https://www.linkedin.com/in/ameliaraine" target="_blank" rel="noreferrer" aria-label="Open LinkedIn (New Window)"><span className="icon-linkedin"></span></a></div>
                    <div><a href="https://twitter.com/msrainea" target="_blank" rel="noreferrer" aria-label="Open Twitter (New Window)"><span className="icon-twitter"></span></a></div>
                </div>
            </div>

            <div id="site-navigation" className="row">
                <nav>
                    <ul>
                        <li id="default"><Link to='/'>Home</Link></li>
                        <li id="about"><Link to='/about'>About</Link></li> 
                        <li id="professional"><Link to='/Professional'>Professional</Link></li>
                        <li id="community"><Link to='/community'>Community</Link></li>
                        <li id="art"><Link to='/art'>Art</Link></li>
                        <li id="craft"><Link to='/craft'>Craft</Link></li>
                        <li id="photography"><Link to='/photography'>Photography</Link></li>
                        <li id="fun"><Link to='/fun'>Just for Fun</Link></li>
                        <li id="training"><Link to='/training'>Training</Link></li>
                    </ul>
                </nav>
            </div>
        </header>
      );
    }
  }
   
  export default Header;