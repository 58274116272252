import React from 'react';
import {Link} from 'react-router-dom';
import bannerabout from '../images/bannerabout.jpg'
import superheroes from '../images/superheroes.jpg'

class About extends React.Component {
    constructor(){
        super();
        this.state={
            name: "About",
            divProfessional: false,
            divPersonable: false,
            divGeneral: false,
            divSkills: false,
            divDocs: false,
            divProcess: false,
            divTime: false,
            divIncident: false,
            divDev: false,
            divPersonal: false,
            toggle: false
        };
        this.hideComponent = this.hideComponent.bind(this);
    }

    hideComponent(varname){
        switch (varname)
        {
            case "divProfessional":
                this.setState({divProfessional: !this.state.divProfessional});
                break;
            case "divPersonable":
                this.setState({divPersonable: !this.state.divPersonable});
                break;
            case "divGeneral":
                this.setState({divGeneral: !this.state.divGeneral});
                break;
            case "divSkills":
                this.setState({divSkills: !this.state.divSkills});
                break;
            case "divDocs":
                this.setState({divDocs: !this.state.divDocs});
                break;
            case "divProcess":
                this.setState({divProcess: !this.state.divProcess});
                break;
            case "divTime":
                this.setState({divTime: !this.state.divTime});
                break;
            case "divIncident":
                this.setState({divIncident: !this.state.divIncident});
                break;
            case "divDev":
                this.setState({divDev: !this.state.divDev});
                break;
            case "divPersonal":
                this.setState({divPersonal: !this.state.divPersonal});
                break;
            case "toggleAll":
                this.setState({divProfessional: !this.state.divProfessional});
                this.setState({divPersonable: !this.state.divPersonable});
                this.setState({toggle: !this.state.toggle})
                break;
            case "toggleAllSub":
                this.setState({divGeneral: !this.state.divGeneral});
                this.setState({divSkills: !this.state.divSkills});
                this.setState({divDocs: !this.state.divDocs});
                this.setState({divProcess: !this.state.divProcess});
                this.setState({divTime: !this.state.divTime});
                this.setState({divIncident: !this.state.divIncident});
                this.setState({divDev: !this.state.divDev});
                this.setState({divPersonal: !this.state.divPersonal});
                break;
            default: return;
        }
    }


    render(){
        const {divProfessional,divPersonable,divGeneral,divSkills,divDocs,divProcess,divTime,divIncident,divDev,divPersonal} = this.state;
        let togClass = this.state.toggle ? "toggle icon-toggle-on" : "toggle icon-toggle-off";

        return (
            <div>
            <h2>About Me</h2>
            <div id="content">
                <div className="col-left">
                    <div id="banner-image">
                        <img src={bannerabout} alt="About Me banner" />
                    </div>
                    <br /><br />
                    I'm a developer with a thirst for knowledge, an eye for opportunity, a passion for creating and unlimited potential. 
                    <br /><br />
                    I'm always looking for a challenge, a project, an innovation, a change.
                    <br /><br />
                    I want to design, create, develop, build and learn. I want techie freedom.
                    
                    <br /><br /><br />
                    <div id="about-nav">
                        <div id="pro" onClick={() => this.hideComponent("divProfessional")}><span className="icon-user-outline"></span><h3>The Professional</h3></div>
                        <div id="personable" onClick={() => this.hideComponent("divPersonable")}><span className="icon-emo-happy"></span><h3>The Personable</h3></div>
                        <div id="toggle" onClick={() => this.hideComponent("toggleAll")}><span className={togClass}></span><h3>Toggle all</h3></div>
                    </div>
                    {divProfessional &&
                    <div id="divProfessional">
                        <h2>The Serious Stuff</h2>
                        <br />
                        <div id="toggle-subs" onClick={() => this.hideComponent("toggleAllSub")}>Toggle all sub-headings</div>
                        <br />
                        Out of the Box. Whilst this inevitably conjures up an obvious cliche, it works quite well for describing me and my work. 
                        <br /><br />
                        I am well known for being challenging, not blindly accepting things "just because" and for questioning anything that appears to be unnecessary.
                        <br /><br />
                        I take pride in resolving problems and inefficiencies, working inside and outside of "the box", pushing boundaries to effect change. 
                        <br /><br />
                        Bill Gates is quoted as saying "I choose a lazy person to do a difficult job because he will find an easy way to do it". I am far from lazy, but I will spend hours developing a solution to make something easier, to cut out repetitive manual tasks, to help people become more effective.
                        <br /><br />
                        This is intended as a brief overview, full details of my skills and work can be found on my <a href="profile.aspx">Training</a> page.
                        <br /><br />              
                        <h3 className="subDivTitle" onClick={() => this.hideComponent("divGeneral")}>General</h3>
                        {divGeneral && 
                        <div id="divGeneral" className="subDiv">
                            Throughout the course of my career I have held support roles working across a range of functions including sales, support and technical in both private and public sector and for both local and international clients.  During this time, I have worked with all levels of staff across the organisations, effectively managing relationships with colleagues and clients in locations around the world.
                            <br /><br />
                            I work well as part of a team and as an individual and am now focussed on development.
                            <br /><br />
                            I am not afraid of facing large amounts of work or tight deadlines and will happily meet challenges and targets head-on, engaging with other resources or learning on the fly as required.
                            <br /><br />
                            I can engage effectively with people at all levels of an organisation, tailoring presentation and delivery of information as required.  I have good working relationships with my colleagues and people have always been happy to come to me for help and advice.  With a natural aptitude for learning I have always been a point of reference for colleagues from any department.
                            <br /><br />
                        </div>
                        }
                        <h3 className="subDivTitle" onClick={() => this.hideComponent("divSkills")}>Skillset</h3>
                        {divSkills &&
                        <div id="divSkills" className="subDiv">
                            I have experience in both front-end and back-end development, I started with Paradox, moved on to MS Access and now work with SQL databases and web-based front-ends.
                            <br /><br />
                            I use JavaScript, jQuery, VBA, VB.Net, C#, Razor, Json and have some knowledge of PHP, Perl and C. 
                            <br /><br />
                            I have used SQL databases both on premise and Azure based and MySQL databases.  I have limited experience with NoSQL databases.
                            <br /><br />
                            I use ASP.Net, I can use HTML and CSS to create web-based applications.
                            <br /><br />
                            I have experience with using APIs to interact with systems.
                            <br /><br />
                            I have experience with PowerShell and have used this to write scripts to automate tasks.
                            <br /><br />
                            I have experience using XML, particularly for automating the publishing of data for use in HTML newsletters.
                            <br /><br />
                            I have experience of Power BI, developing dashboards for reporting from various datasources and some understanding of the R and M languages.
                            <br /><br />
                            I have limited experience in Android development, having produced two small applications. 
                            <br /><br />
                            I am familiar with working with social media, embedding and linking or working with APIs for custom uses as required. I have started working more with the APIs to develop my own applications.
                            <br /><br />
                            I have some experience with using mapping services, for example Google Map, OpenStreetMap, MapBox and Leaflet to present service maps on websites as well as integrating with geolocation services for postcode and lat/lon lookups. 
                            <br /><br />
                            I can troubleshoot and reverse engineer systems to provide support and facilitate development.
                            <br /><br />
                            I have experience taking systems and applications from concept through to delivery. 
                            <br /><br />
                            My experience is largely based on Microsoft products but I do train in other areas when I can. I most regularly use Visual Studio and VSCode, along with SQL Server Management Studio.
                            <br /><br />
                            I am now upskilling in AI to ensure I can continue to improve my developments. I have written a chatbot in react to work with our CMS and started working o nprompt engineering, producing images and content for social media posts.
                        </div>
                        }

                        <h3 className="subDivTitle" onClick={() => this.hideComponent("divDocs")}>Documentation and Training</h3>
                        {divDocs &&
                        <div id="divDocs" className="subDiv">
                            I have been involved in the preparation and maintenance of documentation and training materials from significant commercial proposals to detailed technical publications, application documentation and step-by-step user guides for IT applications.  I have provided training and guidance to users at all levels.
                            <br /><br />
                            I am known for my documentation! If I release a new website or application it will be accompanied by documentation for end users, administrators and developers.
                            <br /><br />
                        </div>
                        }
                        <h3 className="subDivTitle" onClick={() => this.hideComponent("divProcess")}>Process and Analysis</h3>
                        {divProcess &&
                        <div id="divProcess" className="subDiv">
                            I have an eye for detail and a passion for problem solving.  I can analyse processes and provide solutions to simplify or automate where appropriate, saving time and resources.  
                            <br /><br />
                        </div>
                        }
                        <h3 className="subDivTitle" onClick={() => this.hideComponent("divTime")}>Time Management</h3>
                        {divTime &&
                        <div id="divTime" className="subDiv">
                            I have strong time management skills and am able to effectively manage my own workload whilst assisting in the management of a team call stack.  I am able to provide supervision of other staff, act as a gatekeeper to assist in scheduling and prioritisation of work and escalate issues and problems to ensure timely and effective resolution of incoming work.
                            <br /><br />
                            I now work permanently from home, which in itself requires a strong degree of self-discipline and time management. 
                            <br /><br />
                        </div>
                        }
                        <h3 className="subDivTitle" onClick={() => this.hideComponent("divIncident")}>Incidents, Change and Problems</h3>
                        {divIncident &&
                        <div id="divIncident" className="subDiv">
                            I can actively engage in major incident resolution and work with colleagues to manage reactive and pro-active work around other deadlines. I follow relevant change control procedures and engage in problem management processes when required to see longer term issues through to resolution.   
                            <br /><br />
                        </div>
                        }
                        <h3 className="subDivTitle" onClick={() => this.hideComponent("divDev")}>Development</h3>
                        {divDev &&
                        <div id="divDev" className="subDiv">
                            In every role that I have held, there has always been an element of development.  I have found that throughout my career I have been drawn to, and excelled in, developing solutions for a wide range of business applications.  Starting with basic databases, I have continued to develop my skills throughout the years to improve on the systems and technologies that I am able to offer. 
                            <br /><br />
                            I have dedicated a lot of time to improving my skills and this has been done in my own time.  I am willing and keen to continue doing that to ensure that my skills remain up to date and meet the changing demands in business requirements.  With all the new technologies becoming available it is critical to adapt dynamically to be able to develop and support these going forward.
                            <br /><br />
                            I have recently been described as a sponge, I pick things up easily and can quickly apply new knowledge and skills.  I am happy to pick up work that I know little or nothing about and undertake training and research as I go to be able to complete it to the required level.  
                            <br /><br />
                            I often purchase my own sandbox environments to work in so that I can test and validate my skills before applying them to a formal development environment.  I am aware of application lifecycle development and software development processes, managing the transfer of developments from development to production environments and can work within best practice guidelines.
                            <br /><br />
                        </div>
                        }
                        <h3 className="subDivTitle" onClick={() => this.hideComponent("divPersonal")}>Personal</h3>
                        {divPersonal &&
                        <div id="divPersonal" className="subDiv">
                            I am a single mum of one daughter who has now grown up and flown the nest and I love to spend as much time as I can with my family.
                            <br /><br />
                            I own two small businesses and run six social ventures with more in the works!  I provide Admin support to small community groups where needed and in between everything else I love to engage in arts and crafts as well as earning badges for completing monthly tasks, challenges and merit badges, amongst other things!
                            <br /><br />
                            You can find out more on the other pages on this site.
                            <br /><br />
                            I would very much like there to be more hours in the day to achieve everything I'd like to achieve.                    
                            <br /><br />
                        </div>
                        }
                    </div>
                    }
                    {divPersonable &&
                    <div id="divPersonable">
                        <h2>The Personable</h2>
                        <br />
                        <p>My name is Amelia and I am ..... a SuperHero!!</p>
                        <p>Maybe I'm not quite a caped crusader, but I did complete the Microsoft MVA SuperHero training and that's how I acquired the brilliant heroes in my header image.  I also got a t-shirt. I'm pretty proud of completing that course!</p>
                        <p>I am never happier than when I have my head immersed in a project and will happily lose many hours coding until I get things as I want them, or as they need to be</p>
                        <p>I am a perfectionist and that drives my desire to do the best that I possibly can do.</p>
                        <p>I have always had a foot in development, throughout my whole career I have been the touchpoint for anyone needing a solution to a problem and although it has taken quite a few years I am now where I wanted to be, in a development team.  This has been a very positive move for me.</p>
                        <br /><br />
                        <p>I like to use Open Source resources where possible, happily donating to development communities when I find something useful . I prefer to hand code, I find autocomplete and visual editors a nuisance and prefer to learn syntax and use simple text editors.</p>
                        <p>I can work with both front-end and back-end technologies and have experience of various languages, often using more than one in a project to achieve what I need to do.</p>                            
                        <p>I spend a lot of time learning new things, as evidenced by my <Link to="/profile">Training</Link> page and I want to be able to put all of that to good use.</p>
                        <br /><br />
                        <p>My training catalogue is quite diverse, so why the scattergun approach?  It's because I want to learn about everything!  It may make me a master of nothing but it does mean that I can be useful in the diverse environment that I work in. </p>
                        <p>If I've only got starter course knowledge of something it means I at least know where to start if a problem arises. Sometimes just having an awareness of a technology helps and when you have an application catalogue of hundreds that could potentially require input, that's important.</p>
                        <br /><br />
                        <p>I have created several WordPress websites for convenience, I do try to avoid them where possible. I am now converting my .NET projects to React and Node.js, but I still develop using Visual Studio and ASP.Net either in C# or VB. For work, I use the Contensis CMS.  I use HTML, CSS, JavaScript, jQuery, C#, Razor and Json most frequently.</p>
                        <br /><br />
                        <p>I spend a lot of time helping other people, the community and the planet! There is more about that on my <Link to="/community">Community</Link> page</p>
                    </div>
                    }
                    <br /><br />

                </div>
                
                <div className="col-right">
                    <div id="profile-image">
                        <img src={superheroes} alt="Microsoft Virtual Academy Superheroes"/>
                    </div>
                    <div id="profile-details">
                        <br /><br />
                        Amelia Raine
                        <br />
                        Senior Application Developer
                        <br /><br />
                        Social Profiles: 
                        <br />
                        <a href="https://www.linkedin.com/in/ameliaraine" className="profile_link" title="Go to LinkedIn account (New Window)" target="_blank" rel="noreferrer"><span className="icon-linkedin">/ameliaraine</span></a>
                        <br />
                        <a href="https://twitter.com/msrainea" className="profile_link" title="Go to Twitter account (New Window)" target="_blank" rel="noreferrer"><span className="icon-twitter">@msrainea</span></a>
                        <br />
                        <br />
                        <strong>Image Credits:</strong><br />
                        Site Banner:<br />
                        <a href="https://unsplash.com/@m4r1vs?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText" target="_blank" rel="noreferrer" title="External website for photo credit (New Window)">Marius Niveri</a> on <a href="https://unsplash.com/s/photos/code?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText" target="_blank" rel="noreferrer" title="External website for photo credit (New Window)">Unsplash</a>
                        <br /><br />About Banner:<br />
                        <a href="https://pixabay.com/users/geralt-9301/?utm_source=link-attribution&amp;utm_medium=referral&amp;utm_campaign=image&amp;utm_content=1597565" target="_blank" rel="noreferrer" title="External website for photo credit (New Window)">Gerd Altmann</a> from <a href="https://pixabay.com/?utm_source=link-attribution&amp;utm_medium=referral&amp;utm_campaign=image&amp;utm_content=1597565" target="_blank" rel="noreferrer" title="External website for photo credit (New Window)">Pixabay</a>
                        <br /><br />Profile Banner:<br />
                        <a href="https://pixabay.com/users/geralt-9301/?utm_source=link-attribution&amp;utm_medium=referral&amp;utm_campaign=image&amp;utm_content=2706977" target="_blank" rel="noreferrer" title="External website for photo credit">Gerd Altmann</a> from <a href="https://pixabay.com/?utm_source=link-attribution&amp;utm_medium=referral&amp;utm_campaign=image&amp;utm_content=2706977" target="_blank" rel="noreferrer" title="External website for photo credit (New Window)">Pixabay</a>
                        <br /><br />Social Banner:<br />
                        <a href="https://unsplash.com/@goian?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText" target="_blank" rel="noreferrer" title="Eexternal website for photo credit (New Window)">Ian Schneider</a> on <a href="https://unsplash.com/s/photos/code?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText" target="_blank" rel="noreferrer" title="Link to external website for photo credit (New Window)">Unsplash</a>
                    </div>
                </div>
            </div>
            </div>    
        )
    }
}

export default About;
