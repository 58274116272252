import React from 'react';
import Rebel from'../images/rebel.jpeg';

function Fun() {
  return (
    <div id="funPage">
       <h2>Just for Fun</h2>    
      <div id="content">
          <p>If my community, art, photography and craft endeavours aren't enough for you on top of my professional life, here's what I do in my 'free' time!</p>
          <br/><br/>
          <div className="funItem">
            <h2 className="h2Fun">New in April 2024!</h2>
            <p>Clearly I'm not doing enough already (!) and in April 2024 I fell back down a language rabbit hole for a couple of reasons that I will keep to myself! I'm currently blasting my way through DuoLingo in French, Spanish and Swedish at different levels. Follow me there to share quests and help me keep my XP up! I'm currently in the Gold League - I'm only 17 days in - but will hopefully be moving up again shortly.</p>
          </div>
          <br/>
          <div className="funItem">
           <h2 className="h2Fun">Global Citizen</h2>
           I am proud to be a Global Citizen. I spend time as often as possible taking actions and doing challenges to make sure I am informed on issues affecting people around the world.
           <br/><br/>
           Go to the <a className="underline" href="https://www.globalcitizen.org/en/" target="_blank" rel="noopener noreferrer">Global Citizen website</a> to get involved.
          </div>
          <br/>
          <div className="funItem">
            <h2 className="h2Fun">Zooniverse</h2>
           Sometimes I hop over to Zooniverse to help with projects. The last thing I did was transcribe tidal data from hand-written logs into digital logs.
           <br/><br/>
           It's nice to be able to help other users in their projects. I usually try to pick projects that work alongside my other interests so things like the tidal data are perfect!
           <br/><br/>
           Go to the <a className="underline" href="https://www.zooniverse.org/" target="_blank" rel="noopener noreferrer">Zooniverse website</a> to get involved.
          </div>
          <br/>
          <div className="funItem">
            <h2 className="h2Fun">Rebel Badge Book</h2>
            <p>In August 2022, I started working with the Rebel Badge Book. These are advertised as "Merit badges for adults"! I think this is a great idea and am hoping it will help to prompt me to do things I've been putting off and to learn new things along the way, collecting badges as I go.  Why should kids have all the fun!!</p>
            <br/>
            <p>That wasn't quite enough for me though, so I also signed up for The Maverick Awards. This is a "Duke of Edinburgh Awards" style programme for adults. I completed my Bronze award in 2023 and I am now working on my Silver award which will be done by the end of 2024.</p>
            <br/>
            <img src={Rebel} alt="The Rebel Badge Book, Rebel Badge Journal, badge sheets and Maverick Awards log book" />
            <p>You can <a className="underline" href="https://ameliaraine.me.uk/rebel" target="_blank" rel="noopener noreferrer">follow my progress at my Rebel page.</a></p>
          </div>
          <br/>          
        </div>
    </div>
    
  )
}

export default Fun;
