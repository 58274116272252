import React, { Component } from 'react';
 
class Footer extends Component {
  render() {
    return (
      <div>
        <footer>
          &copy; msrainea 2023
        </footer>
      </div>        
    );
  }
}
 
export default Footer;