import React from 'react';
import {Routes, Route} from 'react-router-dom';

import './styles/fontello.css';
import './styles/App.css';

import Header from './constants/header.js';
import Footer from './constants/footer.js';
import About from './pages/About';
import Art from './pages/Art';
import Photography from './pages/photography';
import Craft from './pages/craft';
import Fun from './pages/fun';
import Community from './pages/Community';
import Home from './pages/Home';
import Professional from './pages/Professional';
import Training from './pages/Training';
import MmagePrivacy from './pages/mmage-privacy';

function App() {

  return (
    <div>
      <Header />
      <main>  
          <Routes>
          <Route path="/" exact element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/art" element={<Art />} />
            <Route path="/community" element={<Community />} />
            <Route path="/professional" element={<Professional />} />
            <Route path="/photography" element={<Photography />} />
            <Route path="/craft" element={<Craft />} />
            <Route path="/fun" element={<Fun />} />
            <Route path="/training" element={<Training />} />
            <Route path="/mmage-privacy" element={<MmagePrivacy />} />
          </Routes>
      </main>
      <Footer />
    </div>
    
  );
}

export default App;
